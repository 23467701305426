import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from './../../reducers/index';
import { getAccountSubscription } from '../../reducers/account/account.service';
import {
  PlanQuotaModel,
  QuotaType,
  SubscriptionModel,
} from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { ChangeDetectionType } from '../../enums';

export const hasReachedQuota = (quota: QuotaType, subscription: SubscriptionModel) => {
  const subscriptionQuota: PlanQuotaModel = subscription.plan.quotas[quota];
  if (!subscriptionQuota) {
    return false;
  }
  return subscriptionQuota.usage >= subscriptionQuota.limit;
};

export const quotaRemaining = (quota: QuotaType, subscription: SubscriptionModel): number => {
  const subscriptionQuota: PlanQuotaModel = subscription.plan.quotas[quota];
  if (!subscriptionQuota) {
    return Infinity;
  }
  return subscriptionQuota.limit - subscriptionQuota.usage;
};

@Directive({
  selector: '[subscriptionQuota]',
  standalone: true,
})
export class SubscriptionQuotaDirective implements OnInit, OnDestroy {
  private quotaType$: BehaviorSubject<QuotaType> = new BehaviorSubject(null);
  private changeDetectionType: ChangeDetectionType = ChangeDetectionType.MARK_FOR_CHECK;
  public className: string;

  private subscription = new Subscription();

  private _context: SubscriptionQuotaContext = new SubscriptionQuotaContext();
  private _thenTemplateRef: TemplateRef<SubscriptionQuotaContext> = null;
  private _elseTemplateRef: TemplateRef<SubscriptionQuotaContext> = null;
  private _thenViewRef: EmbeddedViewRef<SubscriptionQuotaContext> = null;
  private _elseViewRef: EmbeddedViewRef<SubscriptionQuotaContext> = null;

  @Input()
  public set subscriptionQuota(quota: QuotaType) {
    this.quotaType$.next(quota);
  }

  @Input()
  public set subscriptionQuotaClass(className: string) {
    this.className = className;
  }

  @Input()
  public set subscriptionQuotaChangeDetection(changeDetectionType: ChangeDetectionType) {
    this.changeDetectionType = changeDetectionType;
  }

  public constructor(
    private _viewContainer: ViewContainerRef,
    private store: Store<AppState>,
    templateRef: TemplateRef<SubscriptionQuotaContext>,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {
    this._thenTemplateRef = templateRef;
  }

  ngOnInit() {
    this.subscription.add(
      combineLatest([
        this.quotaType$.pipe(distinctUntilChanged()),
        this.store.pipe(select(getAccountSubscription)),
      ]).subscribe(([quotaType, accountSubscription]: [QuotaType, SubscriptionModel]) => {
        this._context.$implicit = !hasReachedQuota(quotaType, accountSubscription);
        this._updateView();
        this.changeDetection();
      })
    );
  }

  private changeDetection() {
    if (this.changeDetectionType === ChangeDetectionType.DETECT_CHANGES) {
      this.cd.detectChanges();
    } else {
      this.cd.markForCheck();
    }
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
      if (this.className) {
        const rootElement = this._thenViewRef.rootNodes[0];
        if (rootElement) {
          this.renderer.removeClass(rootElement, this.className);
          this.renderer.removeAttribute(rootElement, 'disabled');
          const icons = rootElement.getElementsByTagName('icon') as HTMLCollection;
          if (icons && icons.length > 0) {
            this.renderer.setStyle(icons[1], 'display', 'none');
          }
        }
      }
    } else {
      // This case presents itself when access to the product feature is denied,
      // but a classname was provided. This means that the class should be applied
      // to the root element and the root element should be shown.
      // Also, if a plus icon is present this should be removed
      // and the lock icon should be shown.
      if (this.className) {
        if (!this._thenViewRef) {
          if (this._thenTemplateRef) {
            this._elseViewRef = null;
            this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
          }
        }

        const rootElement = this._thenViewRef.rootNodes[0];
        if (rootElement) {
          this.renderer.addClass(rootElement, this.className);
          this.renderer.setAttribute(rootElement, 'disabled', 'true');

          const icons = rootElement.getElementsByTagName('icon') as HTMLCollection;
          if (icons && icons.length > 0) {
            this.renderer.setStyle(icons[0], 'display', 'none');
          }
        }
      } else {
        if (!this._elseViewRef) {
          this._viewContainer.clear();
          this._thenViewRef = null;
          if (this._elseTemplateRef) {
            this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
          }
        }
      }
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export class SubscriptionQuotaContext {
  public $implicit: any = null;
}
